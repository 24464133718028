@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }

details,
details summary {
  padding-left:0;
   background-image:none; 
  -webkit-appearance:none;
}
}
